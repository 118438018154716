<template>
    <div class="popup">
        <div class="box">
            <div class="popTitle popTitleArea">
                <p>환자 이관신청 반려</p>
                <div class="buttonArea">
                    <button class="x" @click="$emit('closeCmtPopup')"><i class="micon">close</i></button>
                </div>
            </div>
            <ul class="cont">
                <li>
                    <div class="titArea">
                        <p class="tit">반려 사유 입력*</p>
                    </div>
                    <ul class="contArea">
                        <li style="padding-left: 0;">
                            <v-textarea flat solo density="compact" hide-details="auto" v-model="comment"></v-textarea>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="btnW popBtn">
                <button class="btn default" @click="$emit('closeCmtPopup')" v-ripple>{{ CONST_CLOSE }}</button>
                <button class="btn solid" @click="changeState()" v-ripple>{{ CONST_RETURN }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import API_CHANGE_STATE from '@/API/transferRequest/changeState'
export default {
    components: {
    },
    props: {
        openPopup: {
            default: true
        },
        onClick: {
            default: () => { }
        },
        requestNo: {
            default: ''
        },
        state: {
            default: 'RETURN'
        }
    },
    data() {
        return {
            comment: '',
            isGettingItems: false,

        }
    },
    watch: {},
    created() {},
    mounted() {},
    destroyed() {},
    computed: {},
    methods: {
        async changeState(){
            if (this.isGettingItems) {
                return;
            }
            this.isGettingItems = true;
            if(!this.requestNo) {
                this.showPopup('유효하지 않은 신청입니다<br>다시 확인해주세요.')
                return;
            }
            if(!this.comment || this.comment === '') {
                this.showPopup('반려 사유를 입력하세요')
                return;
            }
            let res = await API_CHANGE_STATE.request(this.requestNo, this.state, this.comment);
            if (res.isSuccess) {
                this.$emit('closeCmtPopup')
                this.showPopup("반려 처리가 완료되었습니다.")
            } else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.isGettingItems = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";

.popup {
    .box{
        width: 580px;
        .x{position: static;}
        .popTitle{
            display:flex; justify-content: space-between; align-items: center;
            p{
                display:flex; justify-content: flex-start; align-items: center;
                i{margin-right: 8px;}
            }
        }
        .cont{
            >li{
                margin-bottom: 24px;
                .titArea{
                    display: flex; justify-content: space-between; align-items: center; margin-bottom: 8px;
                    .tit{margin-bottom: 0;}
                }
                ul.contArea{
                    padding-left: 0;
                    li{
                        display: flex; align-items: center; padding: 0 0 4px 8px; font-size: 14px;
                        p{width: 30%;}
                        span{width: 70%; padding: 0 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; height: 30px; line-height: 30px; color: #111 !important; text-align: center;}
                    }
                }
                .compInfo{
                    font-size: 14px; padding:20px 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; display: flex; flex-direction: column; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
                    img{margin: 0 auto 10px auto;}
                    a{color: inherit;}
                    ul{
                        display:flex; justify-content: space-between; align-items: center; gap: 8px; margin-bottom: 10px;
                        li{
                            &::after{content:'|'; padding-left: 4px;}
                            &:last-child::after{content:''; padding-left: 0;}
                            span{padding-right: 4px; font-weight: bold;}
                        }
                    }
                }
                &.copyright{font-size: 14px; text-align:center; margin-bottom: 0; color:#777;}
            }
            
        }
    }
}

::v-deep .lay-root.thema-default{
    background-color: var(--color-point) !important;
    transition:box-shadow 0.5s,opacity 0.5s;
    &:hover{
        opacity: 0.85; box-shadow:0 4px 7px rgba(0,0,0,0.1);
    }
}

</style>
